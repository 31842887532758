<template>
  <div>
    <breadCrumb title="Seller Policy" :menuList="menuList"></breadCrumb>

    <section class="mb-4">
      <div class="container">
        <div class="p-4 bg-white rounded shadow-sm overflow-hidden mw-100 text-left">
          <template v-if="content.length > 0">
            <div v-html="content"></div>
          </template>
          <template v-else>
            <h1>{{ appName }} Seller Policy</h1>
            <p>
              TikTok Shop is committed to providing a fair and transparent platform for sellers. This policy outlines the rules and regulations that all sellers must follow in order to sell on TikTok Shop.
            </p>
            <h2>1. Seller Eligibility</h2>
            <p>
              In order to sell on TikTok Shop, sellers must meet the following eligibility criteria:
            </p>
            <ul>
              <li>
                <b>Be at least 18 years old.</b>
              </li>
              <li>
                <b>Have a valid business license.</b>
              </li>
              <li>
                <b>Have a valid bank account.</b>
              </li>
              <li>
                <b>Have a valid email address and phone number.</b>
              </li>
            </ul>
            <h2>2. Seller Responsibilities</h2>
            <p>
              Sellers are responsible for the following:
            </p>
            <ul>
              <li>
                <b>Providing accurate and truthful information about their products and services.</b>
              </li>
              <b>Shipping products promptly and accurately.</b>
              <li>
                <b>Providing excellent customer service.</b>
              </li>
              <li>
                <b>Complying with all applicable laws and regulations.</b>
              </li>
            </ul>
            <h2>3. Seller Prohibited Activities</h2>
            <p>
              Sellers are prohibited from engaging in the following activities:
            </p>
            <ul>
              <li>
                <b>Selling counterfeit or illegal products.</b>
              </li>
              <b>Engaging in deceptive or misleading advertising.</b>
              <li>
                <b>Harassing or abusing other users.</b>
              </li>
              <li>
                <b>Engaging in any other activity that is harmful to TikTok Shop or its users.</b>
              </li>
            </ul>
            <h2>4. Seller Account Suspension</h2>
            <p>
              TikTok Shop reserves the right to suspend or terminate any seller account that violates this policy.
            </p>
            <h2>5. Contact Information</h2>
            <p>
              If you have any questions about this policy, please contact TikTok Shop customer service at:
            </p>
          </template>

        </div>
      </div>
    </section>

  </div>
</template>
<script>
import breadCrumb from '@/components/breadCrumb'
import { getArticleLists } from '@/api/index'
export default {
  components: {
    breadCrumb
  },
  data() {
    return {
      menuList: [
        {
          title: 'Seller Policy',
          path: ''
        },
      ],
      content: ''
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName;
    }
  },
  mounted() {
    this.GetArticleData()
  },
  methods: {
    GetArticleData() {
      let f = new FormData()
      f.append('Type', '1')
      getArticleLists(f).then(res => {
        if(res.data.Items.length > 0){
          this.content = res.data.Items[0].Content
        }
      })
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
  }
}
</script>